import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

export default (props) => {
  let { patternUrl, ...imgProps } = props
  return (
    <div
      className="bg-pattern"
      style={{ backgroundImage: `url("${patternUrl}")` }}
    >
      <GatsbyImage alt="" {...imgProps} />
    </div>
  )
}
