import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import ArrowLink from '../../components/misc/arrow-link'
import PatternImg from '../../components/misc/img-patterned'

export default (props) => {
  const { article } = props
  return (
    <section className="featured-article-card">
      <Container fluid="lg">
        <Row className="align-items-center">
          <Col md={{ span: 6, order: 'first' }} lg="5" xs={{ order: 'last' }}>
            <Link
              to={`/articles/${article.slug}`}
              className="text-decoration-none"
            >
              <h3
                className="hu h2 h-w-rule"
                style={{
                  color: `#${article.category.colorPalette.primaryColor}`,
                  borderColor: `#${article.category.colorPalette.primaryColor}`,
                }}
              >
                {article.title}
              </h3>
            </Link>
            <div
              dangerouslySetInnerHTML={{
                __html: article.teaser.childMarkdownRemark.html,
              }}
            />
            <ArrowLink
              color={article.category.colorPalette.primaryColor}
              to={`/articles/${article.slug}`}
            >
              Read More
            </ArrowLink>
          </Col>
          <Col
            className="pb-3 pb-md-0"
            md={{ span: 6 }}
            lg={{ span: 5, offset: 2 }}
          >
            <Link to={`/articles/${article.slug}`}>
              <PatternImg
                image={article.heroImageBW.gatsbyImageData}
                alt={article.title}
                patternUrl={article.category.pattern.file.url}
              />
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
