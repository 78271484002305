import React from 'react'
import get from 'lodash/get'
// import Logo from '../layout/logo'
import { Link, StaticQuery, graphql } from 'gatsby'
import { Container, Row, Col, Nav } from 'react-bootstrap'
import CategoryNavItem from './nav-item'

export default ({ active, header, mobileCollapse = false }) => (
  <StaticQuery
    query={graphql`
      query CategoryNavQuery {
        allContentfulCategory(
          filter: { contentful_id: { ne: "5FkcKCGaTq2LURVvai82vP" } }
          sort: { fields: [position], order: ASC }
        ) {
          edges {
            node {
              name
              slug
              colorPalette {
                primaryColor
                secondaryColor
              }
              icon {
                file {
                  details {
                    image {
                      height
                      width
                    }
                  }
                  url
                }
              }
              iconBW {
                file {
                  details {
                    image {
                      height
                      width
                    }
                  }
                  url
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const categories = get(data, 'allContentfulCategory.edges')
      return (
        <div className="bg-white category-nav-container">
          <Container
            as="section"
            className={`category-nav ${
              mobileCollapse ? 'mobile-collapse' : ''
            }`}
            fluid="lg"
          >
            <Row className="align-items-center">
              <Col xs="12" md="4" lg="3" className="pb-3 pb-lg-0">
                {header ? (
                  header
                ) : (
                  <h3 className="hu h2 text-black">Explore One&nbsp;Yard</h3>
                )}
              </Col>

              <Col>
                <div className="category-nav-list">
                  {categories.map(({ node }, index) => {
                    return (
                      <CategoryNavItem
                        key={node.slug}
                        active={active && active.slug == node.slug}
                        category={node}
                        index={index}
                      />
                    )
                  })}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )
    }}
  />
)
