import React from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import { Container, Row, Col } from 'react-bootstrap'
import ArticleCard from '../components/articles/card'
import CategoryNav from '../components/categories/nav'
import FeaturedArticle from '../components/articles/feature-card'
import LayoutContext from '../components/layout/context'
import { Transition, CSSTransition } from 'react-transition-group'
import { transitionEvents } from '../components/layout/page-transition'
import gsap from 'gsap'
import CategoryPanel from '../components/categories/panel'
import _ from 'lodash'
import Footer from '../components/layout/footer'

class CategoryTemplate extends React.Component {
  static contextType = LayoutContext

  constructor(props) {
    super(props)

    const category = get(props, 'data.contentfulCategory')
    this.state = {
      category,
      articles: get(this.props, 'data.categoryArticles.edges').filter((a) => {
        return (
          !category.featuredArticle ||
          a.node.slug !== category.featuredArticle.slug
        )
      }),
    }

    this.listenHandler = this.listenHandler.bind(this)
  }

  componentWillMount() {
    this.context.set({
      category: this.state.category,
      meta: this.state.category.metaTags,
      hidePresentingSponsor: true,
    })

    if (typeof window !== 'undefined') {
      global.window.addEventListener(transitionEvents.page, this.listenHandler)
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      global.window.removeEventListener(
        transitionEvents.page,
        this.listenHandler
      )
    }
  }

  slideUp() {
    // animate slide up

    gsap.from(`#${this.state.category.slug}`, {
      y: '100vh',
      duration: 0.5,
      ease: 'power4.out',
    })
  }

  slideDown() {
    // animate slide down
    gsap.to(`#${this.state.category.slug}`, {
      y: '100vh',
      duration: 0.5,
      ease: 'power4.out',
    })
  }

  sendBack() {
    gsap.set(`#${this.state.category.slug}`, {
      position: 'absolute',
      top: 0,
      // width: '100%',
      zIndex: 0,
    })
    gsap.to(`#${this.state.category.slug}`, {
      scale: 0.9,
      y: '-205px',
      duration: 0.5,
      ease: 'back',
    })
  }

  bringForward() {
    // need to place this in the dom first, then animate forward
    gsap.set(`#${this.state.category.slug}`, {
      zIndex: 1,
    })
    gsap.from(`#${this.state.category.slug}`, {
      scale: 0.9,
      y: '-205px',
      duration: 0.5,
      ease: 'back',
    })
  }

  listenHandler(evt) {
    // make sure the event is happening for this location
    const {
      detail: {
        label,
        location: { pathname, state: locationState },
      },
    } = evt

    if (pathname === this.props.location.pathname) {
      const fromArticle = _.startsWith(
        locationState?.prevLocation?.pathname,
        '/articles'
      )
      // this event applies to this particular category, go ahead and handle it
      if (label === 'entering') {
        if (fromArticle) {
          // article already had a placeholder tab, we replace it and bring it into focus
          //TODO: Need to handle a change in category (ie. this would actually be slide out
          // gsap.set(`#${this.state.category.slug}`, {
          //   zIndex: 1,
          //   scale: 0.9,
          //   y: '-175px',
          // })
          this.bringForward()
        } else {
          // there's no article overlaying, go
          this.slideUp()
        }
      } else if (label === 'exit') {
        if (fromArticle) {
          this.sendBack()
        } else {
          this.slideDown()
        }
      }
    }
  }

  render() {
    const { category, articles } = this.state
    return (
      <CategoryPanel category={category}>
        <div className="category-content-inner">
          <FeaturedArticle article={category.featuredArticle} />

          <section
            className="articles-list-container"
            style={{
              color: `#${category.colorPalette.primaryColor}`,
            }}
          >
            <Container fluid="lg">
              <div className="articles-list">
                {articles.map(({ node }) => (
                  <ArticleCard key={node.slug} article={node} />
                ))}
              </div>
            </Container>
          </section>

          {category.relatedArticles ? (
            <section
              className="related-content bg-white"
              style={{
                overflow: 'hidden',
              }}
            >
              <div className="side-carousel related-content">
                <div className="side-carousel-title col pb-3">
                  <h3 className="hu h2">Related Content</h3>
                </div>
                <div
                  className="side-carousel-inner col text-black"
                  style={{
                    flex: `1 1`,
                  }}
                >
                  {category.relatedArticles.map((article) => (
                    <ArticleCard
                      key={article.slug}
                      article={article}
                      hideTeaser
                    />
                  ))}
                </div>
              </div>
            </section>
          ) : null}
          {/* <CategoryNav
            mobileCollapse
            active={category}
            header={
              <h2
                className="hu h2"
                style={{
                  color: `#${category.colorPalette.primaryColor}`,
                }}
              >
                {category.name}
              </h2>
            }
          /> */}
        </div>
        <Footer />
      </CategoryPanel>
    )
  }
}

export default CategoryTemplate

export const pageQuery = graphql`
  query CategoryBySlug($slug: String!) {
    contentfulCategory(slug: { eq: $slug }) {
      name
      slug
      headline
      icon {
        file {
          details {
            image {
              height
              width
            }
          }
          url
        }
      }
      featuredArticle {
        slug
        title
        teaser {
          childMarkdownRemark {
            html
          }
        }
        category {
          pattern {
            file {
              url
            }
          }
          colorPalette {
            primaryColor
            secondaryColor
            backgroundColorLight
          }
        }
        heroImage {
          gatsbyImageData(layout: FULL_WIDTH)
        }
        heroImageBW {
          gatsbyImageData(width: 600)
        }
      }
      pattern {
        file {
          url
        }
      }
      colorPalette {
        primaryColor
        secondaryColor
        backgroundColorLight
      }
      relatedArticles {
        slug
        title
        teaser {
          childMarkdownRemark {
            html
          }
        }
        heroImage {
          gatsbyImageData(width: 600)
        }
        heroImageBW {
          gatsbyImageData(width: 600)
        }
        category {
          name
          slug
          pattern {
            file {
              url
            }
          }
          icon {
            file {
              details {
                image {
                  height
                  width
                }
              }
              url
            }
          }
        }
      }
      metaTags {
        title
        image {
          gatsbyImageData(width: 1200, height: 630)
        }
        url
        description {
          description
        }
      }
    }
    categoryArticles: allContentfulArticle(
      filter: {
        contentful_id: { ne: "21MSPnYzKUYFDPI7DcGeVD" }
        category: { slug: { eq: $slug } }
      }
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          slug
          title
          teaser {
            childMarkdownRemark {
              html
            }
          }
          heroImage {
            gatsbyImageData(width: 350, height: 196)
          }
          heroImageBW {
            gatsbyImageData(width: 350, height: 196)
          }
          category {
            name
            slug
            pattern {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`
