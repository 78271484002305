import React from 'react'
import { Card, Button } from 'react-bootstrap'
import { Link } from 'gatsby'
import PatternImg from '../misc/img-patterned'
import ArrowLink from '../../components/misc/arrow-link'

export default ({ article, hideTeaser }) => (
  <Card className="article-card">
    <Link to={`/articles/${article.slug}`}>
      <PatternImg
        image={article.heroImageBW.gatsbyImageData}
        patternUrl={article.category.pattern.file.url}
        // srcSet={article.heroImage.fixed}
        alt={article.title}
        className="card-img"
        // style={{ backgroundImage: 'url("")' }}
      />
    </Link>
    <Card.Body>
      {hideTeaser ? (
        <img
          src={article.category.icon.file.url}
          className="article-category-icon"
        />
      ) : null}
      <Card.Title
        as={Link}
        to={`/articles/${article.slug}`}
        className={`article-title ${hideTeaser ? '' : 'h-w-rule'}`}
      >
        {article.title}
      </Card.Title>
      {hideTeaser ? null : (
        <Card.Text
          as="div"
          dangerouslySetInnerHTML={{
            __html: article.teaser.childMarkdownRemark.html,
          }}
        ></Card.Text>
      )}
    </Card.Body>
    <Card.Footer>
      <ArrowLink to={`/articles/${article.slug}`}>Read More</ArrowLink>
    </Card.Footer>
  </Card>
)
